import React from "react";

const Pricing = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container" style={{maxWidth:"90%"}}>
        {/* section title */}
        <div className="row justify-content-center  pt-20">
          <div className="col-12">
            <div
              className="text-center mb-10 mb-lg-10 dark-mode-texts"
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <h2 className="text-black font-size-10 letter-spacing-n83">
                Bespoke Video Player Features
              </h2>
              <h3 style={{color:"#666"}} className="font-size-7"><span className="Videos counter">Instant Playback</span>, <span className="Links counter">Brand Watermark</span>, <span className="Images counter">Poster Titles & Description</span> &amp; <span className="Popups counter">Moving Background</span></h3>
            </div>
          </div>
        </div>
        {/* section title */}
        {/* Pricing Table */}
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8">
                  <div className="row justify-content-left">         
                  <h4 className='mt-10'>Speed & quality</h4>
          <p>Engineered to support high-resolution videos for high volume traffic websites. Our focus is on enhancing viewer engagement and delivering unparalleled quality, irrespective of the viewing environment and connection speed.</p>
          <h4>Simple to integrate</h4>
          <p>Incorporating videos into your website is effortlessly achieved with Digma's compact embed codes. Our concise code snippets make embedding a breeze, ensuring that your videos load swiftly for an optimal user experience.</p>
          <h4>Adaptable and mobile-Optimized</h4>
          <p>Our responsive player ensures seamless video playback on mobile devices, dynamically adjusting to various screen sizes. Digma videos seamlessly play inline on mobile, directing attention to your content without causing distractions on your site. Whether your audience views content on desktop or mobile, all your customizations maintain consistency.</p>
                                     
                  </div>
            </div>
          </div>
        {/* End Pricing Table */}
      </div>
    </div>
  );
};

export default Pricing;
