import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PageWrapper from '../components/PageWrapper';
import VideoExample from '../sections/dam/video-example-duck';
import VideoExample2 from '../sections/comparison/video-comparison';

import Bespoke from "../sections/dam/Bespoke";
import Wave from "../sections/home1/Wave";
import WaveReverse from '../sections/common/WaveReverse';
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/default.jpg';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const IndexPage = ({ data }) => {
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (prevPos.y > currPos.y) {
      setShowReveal(false);
    } else {
      setShowReveal(true);
    }
  });
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-left',
          headerFluid: false,
          version: 'home-version',
          footerStyle: 'digma',
        }}
      >
        <SEO
          title="Ollie Verschoyle"
          description="Ollie is a Director of Photography with over fifteen years of experience working across a wide range of commercials, documentaries and fiction."
          image={metaImg}
        />
        <div style={{maxWidth: '1080px'}} className='container mt-25 mb-15 d-flex flex-column justify-content-center'>
          <VideoExample />
          <h3 className='mb-5 mt-10'>Ollie Verschoyle</h3>
          <p>Ollie shoots in a distinctive, premium documentary style which is always led by story and an emotional connection to the narrative. He is equally at home shooting highly stylised, carefully planned pieces or with work that requires a much more instinctive and spontaneous approach and is always guided by a connection to the moment unfolding before him.</p>
          <p>Having worked extensively around the world Ollie is highly adaptable to filming in varied and challenging environments.  He is based in London and holds British, American & Irish passports.</p>
          <p>Ollie is also a short filmmaker, writing and directing short form narrative and documentary.</p>      
          <p>For Bookings: <a href="tel:+447970429519">+44 (0) 7970 429519</a><br />Website: <a href="https://www.ollieverschoyle.com/" target="_blank">ollieverschoyle.com</a></p>
        </div>
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <Bespoke className="grey" />    
                 
        <WaveReverse background="#F7F9FC" color="#fff" />         
        <div className="d-flex align-items-center pt-0 pt-md-0 pt-lg-10 pb-8 pb-md-12 pb-lg-23">
          <div className="container">
            {/* Contact Form */}
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                {/* contact details */}
                <div className="pt-0 pb-10 max-w-536 mx-auto">
                <h2>Unlock the power of custom video experiences!</h2>
                <p>Are you ready to elevate your video content beyond the limitations of platforms like YouTube and Vimeo? Our bespoke video player offers unparalleled customization, enhanced branding opportunities, and a seamless user experience tailored specifically for your audience.</p>
                  {/* <Link to="/book-a-meeting" className="btn mt-5 btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">
                    Book A Meeting
                  </Link>                   */}
                </div>
              </div>
              <div className="d-block d-m-none col-lg-5 col-md-12 col-sm-12  col-xs-12 text-left">
                  <form
                    name="contact-form"
                    method="POST"
                    action="/thank-you"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-aos="fade-up"
                    data-aos-duration={1100}
                    data-aos-once="true"
                  >
                    <input type="hidden" name="contact-form" value="contact-form" />
                    <h4 className="font-size-7 text-digma-dark mb-5 text-left">About you</h4>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="first_name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="First Name*"
                        id="firstName"
                        required
                      />
                    </div>
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="last_name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Last Name*"
                        id="lastName"
                        required
                      />
                    </div>                    
                    {/* Email */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder=" Your Email Address*"
                        id="email"
                        required
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="number"
                        name="number"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Your Contact Number"
                        id="number"
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                    <textarea
                        type="text"
                        name="message"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Question*"
                        id="position"
                        required
                      />
                    </div>                    
                    <h4 className="font-size-7 text-digma-dark mb-5 text-left">About your business</h4>
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="company name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Company Name"
                        id="company"
                      />
                    </div>
                    <div className="form-group mb-7 position-relative">
                    <input
                        type="text"
                        name="company industry"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Company Industry"
                        id="position"
                      />
                    </div>
                    
                    
                    
                    <div className="button">
                      <button
                        type="submit"
                        href="/#"
                        className="btn btn-digma h-55 w-100 rounded-4"
                      >
                        Send
                      </button>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>         
      </PageWrapper>
    </>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query {
    bgImg: file(relativePath: { eq: "home-1/png/desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1240) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bgImg2: file(relativePath: { eq: "home-6/png/hero-mobile-empty.png" }) {
      childImageSharp {
        fluid(maxWidth: 1240) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
